@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent horizontal and vertical scroll */
}
